import React, { useState } from 'react';
import cx from 'classnames';
import GearIcon from 'public/images/gear-icon.svg';
import isProduction from '@/utils/isProduction';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export default function Dev() {
    const [isOpen, setOpen] = useState(0);

    return (
        <div>
            <div className={cx(['bottom-15 fixed z-[99] h-full w-[250px]', isOpen ? 'right-0' : 'right-[-245px]'])}>
                <div className={cx('absolute bottom-0 right-0 block h-full w-full overflow-hidden bg-slate-100 p-3 text-black transition-all duration-100')}>
                    <p>{process.env.NEXT_PUBLIC_GRAPHQL}</p>
                    <p>{process.env.NEXT_PUBLIC_WP}</p>
                    <p>NODE_ENV: {process.env.NODE_ENV}</p>
                    <p>isProduction: {isProduction() ? 'true' : 'false'}</p>
                    <p>VERCEL_ENV: {publicRuntimeConfig.VERCEL_ENV}</p>
                </div>
                <div
                    className="absolute left-[-20px] flex h-7 w-7 cursor-pointer items-center justify-center bg-slate-100"
                    onClick={() => setOpen(!isOpen)}
                    role="presentation"
                    onKeyDown={() => setOpen(!isOpen)}>
                    <GearIcon />
                </div>
            </div>
        </div>
    );
}
